import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main',
        component: Home,
        id: 1
    },
    {
        path: '/students-payments',
        name: 'StudentsPayments',
        component: () => import('../views/pupils/StudentsPayments.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        id: 2,
        component: () => import('../views/Settings.vue'),
        children: [
            {
                id: 76,
                name: 'system',
                path: 'system',
                component: () => import('../views/settings/System.vue'),
                children: [
                    {
                        name: 'menu',
                        path: 'admin',
                        component: () => import('../views/settings/System/Admin.vue'),
                    },
                    {
                        name: 'notifications',
                        path: 'notifications',
                        component: () => import('../views/settings/System/Notifications.vue'),
                    },
                    {
                        name: 'mailings',
                        path: 'sms',
                        component: () => import('../views/settings/System/Sms.vue'),
                    },
                    {
                        name: 'templates',
                        path: 'templates',
                        component: () => import('../views/settings/System/Templates.vue'),
                        children: [
                            {
                                name: 'editTemplate',
                                path: ':id/edit/:type',
                                component: () => import('../views/settings/System/TemplatesSettings/Edit.vue'),
                            },
                        ]
                    },
                ]
            },
            {
                id: 77,
                name: 'calendar',
                path: 'calendar',
                component: () => import('../views/settings/Calendar.vue'),
                children: [
                    {
                        name: 'years',
                        path: 'years',
                        component: () => import('../views/settings/Calendar/Years.vue'),
                    },
                    {
                        name: 'holidays',
                        path: 'holidays',
                        component: () => import('../views/settings/Calendar/Holidays.vue'),
                    },
                ]
            },
            {
                id: 78,
                name: 'community',
                path: 'employees',
                component: () => import('../views/settings/Employees.vue'),
                children: [
                    {
                        name: 'roles',
                        path: 'roles',
                        component: () => import('../views/settings/Employees/Roles.vue'),
                    },
                    {
                        name: 'access',
                        path: 'access',
                        component: () => import('../views/settings/Employees/Access.vue'),
                        children: [
                            {
                                name: 'settings',
                                path: 'settings',
                                component: () => import('../views/settings/Employees/AccessSettings/Settings.vue'),
                            }
                        ]
                    },
                    {
                        name: 'status',
                        path: 'status',
                        component: () => import('../views/settings/Employees/Status.vue'),
                    },
                    {
                        name: 'salary',
                        path: 'salary',
                        component: () => import('../views/settings/Employees/Salary.vue'),
                    },
                    {
                        name: 'payments',
                        path: 'payments',
                        component: () => import('../views/settings/Employees/Payments.vue'),
                    },
                    {
                        name: 'penalty',
                        path: 'penalty',
                        component: () => import('../views/settings/Employees/Penalty.vue'),
                    },
                ]
            },
            {
                id: 79,
                name: 'education',
                path: 'education',
                component: () => import('../views/settings/Education.vue'),
                children: [
                    {
                        name: 'study',
                        path: 'study',
                        component: () => import('../views/settings/Education/Study.vue'),
                    },
                    {
                        name: 'umk',
                        path: 'umk',
                        component: () => import('../views/settings/Education/Umk.vue'),
                    },
                ]
            },
            {
                id: 80,
                name: 'plugins',
                path: 'plugins',
                component: () => import('../views/settings/Plugins.vue'),
            },
            {
                id: 81,
                name: 'more',
                path: 'more',
                component: () => import('../views/settings/More.vue'),
                children: [
                    {
                        name: 'logo',
                        path: 'logo',
                        component: () => import('../views/settings/More/Logo.vue'),
                    },
                    // {
                    //     name: 'Баннер на главной',
                    //     path: 'banner',
                    //     component: () => import('../views/settings/More/Banner.vue'),
                    // },
                    {
                        name: 'blacklist',
                        path: 'blacklist',
                        component: () => import('../views/settings/More/BlackList.vue'),
                    },
                    {
                        name: 'auth',
                        path: 'auth',
                        component: () => import('../views/settings/More/Auth.vue'),
                    },
                    {
                        name: 'developers',
                        path: 'developers',
                        component: () => import('../views/settings/More/Developers.vue'),
                    },
                ]
            },
            {
                id: 82,
                name: 'editList',
                path: 'list/:key',
                component: () => import('../views/settings/ListElements.vue')
            }
        ]
    },
    {
        path: '/auth',
        name: 'Вход в систему',
        component: () => import('../views/Auth.vue'),
    },
    {
        id: 15,
        path: '/employees',
        name: 'employees',
        component: () => import('../views/Employees.vue'),
        children: [
            {
                id: 16,
                roleId: 2,
                name: 'admins',
                path: 'admins',
                component: () => import('../views/employees/Admins.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/employees/components/ProfileSimple.vue')
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/employees/components/ProfileSimple.vue')
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/employees/components/ProfileSimpleView.vue')
                    },
                    {
                        name: 'fine',
                        path: ':id/outcome',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'award',
                        path: ':id/income',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'advance',
                        path: ':id/advance',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'gave',
                        path: ':id/gave',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    }
                ]
            },
            {
                id: 17,
                roleId: 9,
                name: 'directors',
                path: 'directors',
                component: () => import('../views/employees/Directors.vue'),
                children: [{
                    name: 'profile',
                    path: ':id/edit',
                    component: () => import('../views/employees/components/ProfileSimple.vue')
                },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/employees/components/ProfileSimple.vue')
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/employees/components/ProfileSimpleView.vue')
                    },
                    {
                        name: 'fine',
                        path: ':id/outcome',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'award',
                        path: ':id/income',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'advance',
                        path: ':id/advance',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'gave',
                        path: ':id/gave',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    }
                ]
            },
            {
                id: 18,
                roleId: 11,
                name: 'managers',
                path: 'managers',
                component: () => import('../views/employees/Managers.vue'),
                children: [{
                    name: 'profile',
                    path: ':id/edit',
                    component: () => import('../views/employees/components/ProfileSimple.vue')
                },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/employees/components/ProfileSimple.vue')
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/employees/components/ProfileSimpleView.vue')
                    },
                    {
                        name: 'fine',
                        path: ':id/outcome',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'award',
                        path: ':id/income',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'advance',
                        path: ':id/advance',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'gave',
                        path: ':id/gave',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    }
                ]
            },
            {
                id: 19,
                roleId: 10,
                name: 'methodists',
                path: 'methodists',
                component: () => import('../views/employees/Methodists.vue'),
                children: [{
                    name: 'profile',
                    path: ':id/edit',
                    component: () => import('../views/employees/components/ProfileSimple.vue')
                },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/employees/components/ProfileSimple.vue')
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/employees/components/ProfileSimpleView.vue')
                    },
                    {
                        name: 'fine',
                        path: ':id/outcome',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'award',
                        path: ':id/income',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'advance',
                        path: ':id/advance',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'gave',
                        path: ':id/gave',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    }
                ]
            },
            {
                id: 20,
                roleId: 12,
                name: 'teachers',
                path: 'teachers',
                component: () => import('../views/employees/Teachers.vue'),
                children: [{
                    name: 'profile',
                    path: 'add',
                    component: () => import('../views/employees/teachers/Profile.vue')
                },
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/employees/teachers/Profile.vue')
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/employees/teachers/ProfileView.vue')
                    },
                    {
                        name: 'teacherSalary',
                        path: ':id/salary',
                        component: () => import('../views/employees/teachers/SalaryNew.vue')
                    },
                    {
                        name: 'schedule',
                        path: ':id/schedule',
                        component: () => import('../views/employees/teachers/Schedule.vue')
                    },
                    {
                        name: 'fine',
                        path: ':id/outcome',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'award',
                        path: ':id/income',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    },
                    {
                        name: 'advance',
                        path: ':id/advance',
                        component: () => import('../views/employees/teachers/Penalty.vue')
                    }
                ]
            },
            {
                path: "role/:roleId",
                component: () => import('../views/employees/Role.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/employees/components/ProfileSimple.vue')
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/employees/components/ProfileSimple.vue')
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/employees/components/ProfileSimpleView.vue')
                    }
                ]
            }
        ]
    },
    {
        id: 22,
        path: '/establishments',
        name: 'establishments',
        component: () => import('../views/Establishments.vue'),
        children: [
            {
                id: 23,
                name: 'branches',
                path: 'schools',
                component: () => import('../views/establishments/Schools.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/establishments/schools/Profile.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/establishments/schools/Profile.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/establishments/schools/ProfileView.vue'),
                    },
                    {
                        name: 'classrooms',
                        path: ':id/classrooms',
                        component: () => import('../views/establishments/schools/classrooms/Classrooms.vue'),
                    },
                    {
                        name: 'property',
                        path: ':id/property',
                        component: () => import('../views/establishments/schools/property/Property.vue'),
                        children: [{
                            name: 'profile',
                            path: ':type/add',
                            component: () => import('../views/establishments/schools/property/Add.vue'),
                        },
                            {
                                name: 'profile',
                                path: ':type/:item/edit',
                                component: () => import('../views/establishments/schools/property/Edit.vue'),
                            },
                            {
                                name: 'profile',
                                path: ':type/:item/view',
                                component: () => import('../views/establishments/schools/property/View.vue'),
                            },
                        ],
                    }
                ]
            },
            {
                id: 24,
                name: 'kindergartens',
                path: 'nursery',
                component: () => import('../views/establishments/Nursery.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/establishments/nursery/Edit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/establishments/nursery/Add.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/establishments/nursery/View.vue'),
                    },
                ],

            },
            {
                id: 25,
                name: 'corpHome',
                path: 'corp',
                component: () => import('../views/establishments/Corp.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/establishments/corp/Edit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/establishments/corp/Edit.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/establishments/corp/View.vue'),
                    },
                    {
                        name: 'schedule',
                        path: ':id/schedule',
                        component: () => import('../views/establishments/corp/Schedule.vue'),
                    },
                ],
            },
            {
                id: 26,
                name: 'home',
                path: 'home',
                component: () => import('../views/establishments/Home.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/establishments/home/Edit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/establishments/home/Edit.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/establishments/home/View.vue'),
                    },
                    {
                        name: 'schedule',
                        path: ':id/schedule',
                        component: () => import('../views/establishments/home/Schedule.vue'),
                    },
                ],
            },
        ]
    },
    {
        id: 27,
        name: 'pupils',
        path: '/pupils',
        component: () => import('../views/Pupils.vue'),
        children: [
            {
                id: 28,
                name: 'students',
                path: 'peoples',
                component: () => import('../views/pupils/Peoples.vue'),
                children: [{
                    name: 'certificate',
                    path: ':id/certificate',
                    component: () => import('../views/pupils/peoples/Certificate.vue'),
                },
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/pupils/peoples/ProfileEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/pupils/peoples/ProfileView.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/pupils/peoples/ProfileAdd.vue'),
                    },
                    {
                        name: 'books',
                        path: ':id/books',
                        component: () => import('../views/pupils/peoples/Books/Books.vue'),
                    },
                    {
                        name: 'studentPayments',
                        path: ':id/payments',
                        component: () => import('../views/pupils/peoples/Payments/Payments.vue'),
                    },
                    {
                        name: 'import',
                        path: 'import',
                        component: () => import('../views/dropDownload/dropDownload.vue'),
                    },
                ]
            },
            {
                id: 29,
                name: 'groups',
                path: 'groups',
                component: () => import('../views/pupils/Groups.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/pupils/groups/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/pupils/groups/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/pupils/groups/GroupView.vue'),
                    },
                    {
                        name: 'schedule',
                        path: ':id/schedule',
                        component: () => import('../views/pupils/groups/Schedule.vue'),
                    },
                ]
            },
            {
                id: 30,
                name: 'minigroups',
                path: 'minigroups',
                component: () => import('../views/pupils/MiniGroups.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/pupils/groups/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/pupils/groups/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/pupils/groups/GroupView.vue'),
                    },
                    {
                        name: 'schedule',
                        path: ':id/schedule',
                        component: () => import('../views/pupils/groups/Schedule.vue'),
                    },
                ]
            },
            {
                id: 31,
                name: 'individuals',
                path: 'individual',
                component: () => import('../views/pupils/Individual.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/pupils/individuals/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/pupils/individuals/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/pupils/individuals/GroupView.vue'),
                    },
                    {
                        name: 'schedule',
                        path: ':id/schedule',
                        component: () => import('../views/pupils/individuals/Schedule.vue'),
                    },
                ]
            },
            {
                id: 32,
                name: 'corp',
                path: 'corp-pupils',
                component: () => import('../views/pupils/Corp.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/pupils/corp/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/pupils/corp/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/pupils/corp/GroupView.vue'),
                    },
                    {
                        name: 'schedule',
                        path: ':id/schedule',
                        component: () => import('../views/pupils/corp/Schedule.vue'),
                    },
                ]
            },
            {
                id: 33,
                name: 'kindergroups',
                path: 'nursery-pupils',
                component: () => import('../views/pupils/Nursery.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/pupils/groups/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/pupils/groups/GroupEdit.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/pupils/groups/GroupView.vue'),
                    },
                    {
                        name: 'schedule',
                        path: ':id/schedule',
                        component: () => import('../views/pupils/groups/Schedule.vue'),
                    },
                ]
            },
        ],
    },
    {
        id: 34,
        name: 'schedule',
        path: '/schedule',
        component: () => import('../views/Schedule.vue'),
        children: [
            {
                id: 35,
                name: 'branches',
                path: 'schools',
                component: () => import('../views/schedule/Schools.vue')
            },
            {
                id: 36,
                name: 'kindergartens',
                path: 'nursery',
                component: () => import('../views/schedule/Nursery.vue')
            },
            {
                id: 37,
                name: 'corpHome',
                path: 'corp',
                component: () => import('../views/schedule/Corp.vue')
            },
            {
                id: 38,
                name: 'home',
                path: 'home',
                component: () => import('../views/schedule/Home.vue')
            },
            {
                id: 39,
                name: 'consHours',
                path: 'consult',
                component: () => import('../views/schedule/Consult.vue')
            },
        ]
    },
    {
        id: 40,
        name: 'accounting',
        path: '/accounting',
        component: () => import('../views/Accounting.vue'),
        children: [
            {
                id: 41,
                name: 'journal',
                path: 'journal',
                component: () => import('../views/accounting/Journal.vue'),
            },
            {
                id: 42,
                name: 'consHours',
                path: 'consult',
                component: () => import('../views/accounting/Consult.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/accounting/Consult/Edit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/accounting/Consult/Edit.vue'),
                    }
                ]
            },
            {
                id: 43,
                name: 'replacements',
                path: 'replacements',
                component: () => import('../views/accounting/Replacements.vue'),
                children: [
                    {
                        name: 'profile',
                        path: ':id/edit',
                        component: () => import('../views/accounting/Replacements/Edit.vue'),
                    },
                    {
                        name: 'profile',
                        path: 'add',
                        component: () => import('../views/accounting/Replacements/Edit.vue'),
                    },
                    {
                        name: 'profile',
                        path: ':id/view',
                        component: () => import('../views/accounting/Replacements/View.vue'),
                    }
                ]
            }
        ],
    },
    {
        id: 44,
        name: 'reports',
        path: '/reports',
        component: () => import('../views/Reports.vue'),
        children: [
            {
                id: 45,
                name: 'performance',
                path: 'performance',
                component: () => import('../views/reports/Performance.vue'),
            },
            {
                id: 46,
                name: 'attendance',
                path: 'attendance',
                component: () => import('../views/reports/Attendance.vue'),
            },
            {
                id: 47,
                name: 'paymentsReport',
                path: 'payments',
                component: () => import('../views/reports/Payments.vue'),
            },
            {
                id: 48,
                name: 'salary',
                path: 'salary',
                component: () => import('../views/reports/Salary.vue'),
            },
            {
                id: 49,
                name: 'busyness',
                path: 'busyness',
                component: () => import('../views/reports/Busyness.vue'),
            },
            {
                id: 50,
                name: 'umkReport',
                path: 'umk',
                component: () => import('../views/reports/Umk.vue'),
            },
            {
                id: 51,
                name: 'library',
                path: 'library',
                component: () => import('../views/reports/Library.vue'),
            },
            {
                id: 52,
                name: 'stats',
                path: 'stats',
                component: () => import('../views/reports/Stats.vue'),
            },
            {
                id: 53,
                name: 'refusals',
                path: 'refusals',
                component: () => import('../views/reports/Refusals.vue'),
            },
            {
                id: 54,
                name: 'consHours',
                path: 'consult',
                component: () => import('../views/reports/Consult.vue'),
            },
            {
                id: 55,
                name: 'expenses',
                path: 'expenses',
                component: () => import('../views/reports/Expenses.vue'),
            },
            {
                id: 56,
                name: 'history',
                path: 'history',
                component: () => import('../views/reports/History.vue')
            },
            {
                id: 57,
                name: 'composition',
                path: 'composition',
                component: () => import('../views/reports/Composition.vue'),
            },
            {
                id: 58,
                name: 'funnel',
                path: 'funnel',
                component: () => import('../views/reports/Funnel.vue'),
            },
            {
                id: 59,
                name: 'source',
                path: 'deals',
                component: () => import('../views/reports/Deals.vue'),
            },
            {
                id: 101,
                name: 'memory',
                path: 'memory',
                component: () => import('../views/reports/Memory.vue'),
            },
        ],
    },
    {
        id: 60,
        name: 'annual',
        path: '/yearly-reports',
        component: () => import('../views/Yearly.vue'),
        children: [
            {
                id: 61,
                name: 'employees',
                path: 'yearly-employees',
                component: () => import('../views/yearly/Employees.vue'),
            },
            {
                id: 62,
                name: 'students',
                path: 'yearly-pupils',
                component: () => import('../views/yearly/Pupils.vue'),
            },
            {
                id: 63,
                name: 'earnings',
                path: 'earnings',
                component: () => import('../views/yearly/Earnings.vue'),
            },
            {
                id: 64,
                name: 'annualSalary',
                path: 'salary',
                component: () => import('../views/yearly/Salary.vue'),
            },
            {
                id: 65,
                name: 'certificates',
                path: 'certificates',
                component: () => import('../views/yearly/Certificates.vue'),
            }
        ]
    },
    {
        id: 66,
        name: 'requests',
        path: '/requests',
        component: () => import('../views/Requests.vue'),
    },
    {
        id: 67,
        name: 'notifications',
        path: '/mail',
        component: () => import('../views/Mail.vue'),
        children: [
            {
                name: 'profile',
                path: 'mail-item/:id',
                component: () => import('../views/mail/MailDetailed.vue')
            },
            {
                name: 'profile',
                path: 'new',
                component: () => import('../views/mail/New.vue')
            },
        ]
    },
    {
        id: 82,
        name: 'support',
        path: '/support',
        component: () => import('../views/Support.vue'),
        children: [
            {
                name: 'add',
                path: 'add',
                component: () => import('../views/support/Edit.vue'),
            },
            {
                name: 'edit',
                path: 'edit/:id',
                component: () => import('../views/support/Edit.vue')
            }
        ]
    },
    {
        id: 68,
        name: 'news',
        path: '/news',
        component: () => import('../views/News.vue'),
        children: [
            {
                name: 'profile',
                path: 'add',
                component: () => import('../views/news/New.vue'),
            },
            {
                name: 'profile',
                path: 'edit/:id',
                component: () => import('../views/news/Edit.vue'),
            }
        ]
    },
    {
        id: 69,
        name: 'clients',
        path: '/franchise',
        component: () => import('../views/Franchise.vue'),
        children: [
            {
                name: 'profile',
                path: ':id/view',
                component: () => import('../views/franchise/View.vue'),
            },
            {
                name: 'profile',
                path: ':id/edit',
                component: () => import('../views/franchise/Profile.vue'),
            },
            {
                name: 'profile',
                path: 'add',
                component: () => import('../views/franchise/Profile.vue'),
            },
            {
                name: 'studentPayments',
                path: ':id/payments',
                component: () => import('../views/franchise/Payments/Table.vue'),
            },
        ]
    },
    {
        id: 70,
        name: 'documents',
        path: '/documents',
        component: () => import('../views/Documents.vue'),
    },
    {
        id: 71,
        name: 'affairs',
        path: '/affairs',
        component: () => import('../views/Affairs.vue'),
    },
    {
        id: 72,
        name: 'tasks',
        path: '/tasks',
        component: () => import('../views/Tasks.vue'),
        children: [
            {
                name: 'profile',
                path: ':id/edit',
                component: () => import('../views/tasks/Edit.vue'),
            },
            {
                name: 'profile',
                path: 'add',
                component: () => import('../views/tasks/Edit.vue'),
            },
            {
                name: 'profile',
                path: ':id/view',
                component: () => import('../views/tasks/View.vue'),
            }
        ]
    },
    {
        id: 201,
        name: 'tasks',
        path: '/tasks_new',
        component: () => import('../views/taskManager/index.vue'),
        children: [
            {
                name: 'profile',
                path: ':id/edit',
                component: () => import('../views/taskManager/index.vue'),
            },
            {
                name: 'archive',
                path: 'archive',
                component: () => import('../views/taskManager/index.vue')
            }
        ]
    },

    {
        id: 73,
        name: 'deals',
        path: '/deals',
        component: () => import('../views/Deals.vue'),
        children: [
            {
                name: 'profile',
                path: ':id/edit',
                component: () => import('../views/deals/DealWrap.vue'),
            },
            {
                name: 'profile',
                path: 'add',
                component: () => import('../views/deals/DealWrap.vue'),
            },
            {
                name: 'profile',
                path: ':id/view',
                component: () => import('../views/deals/View.vue'),
            },
        ]
    },
    {
        id: 74,
        name: 'analytics',
        path: '/analytics',
        component: () => import('../views/Analytics.vue'),
    },
    {
        id: 75,
        name: 'orders',
        path: '/orders',
        component: () => import('../views/Orders.vue'),
    },
    {
        name: 'profile',
        path: '/account',
        component: () => import('../views/Account.vue'),
        children: [
            {
                name: 'personal',
                path: 'personaldata',
                component: () => import('../views/account/PersonalData.vue'),
            },
            {
                name: 'passport',
                path: 'passport',
                component: () => import('../views/account/Passport.vue'),
            },
            {
                name: 'bank',
                path: 'bank',
                component: () => import('../views/account/Bank.vue'),
            },
            {
                name: 'widgets',
                path: 'widgets',
                component: () => import('../views/account/Widgets.vue'),
            }
        ]
    },
    {
        name: 'search',
        path: '/search',
        component: () => import('../views/Search.vue'),
    },
    {
        name: '404',
        path: '/404',
        component: () => import('../views/404.vue'),
    },
    {
        name: '403',
        path: '/403',
        component: () => import('../views/403.vue'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router